import * as Fathom from "fathom-client"
import { Observer, observer } from "mobx-react"
import type React from "preact/compat"
import {
	BookOpen,
	Box,
	Cloud,
	ExternalLink,
	GitHub,
	LogOut,
	Mail,
	Plus,
	Settings,
	Slack,
	User,
} from "react-feather"
import {
	Button,
	Divider,
	DropdownMenu,
	Icon,
	Link,
	Skeleton,
	Text,
	View,
} from "reshaped"

import { appStore, featureFlagNotificationsCenter } from "#app"
import { NotificationCenter } from "#components/NotificationCenter"
import { getLinkProps, router, useAllRoutes } from "#router"

import { useEffect, useRef, useState } from "preact/hooks"
import { NavigationSectionsType } from "../../enums"
import Logo from "./logo.svg?react"
import css from "./navigation.module.css"

export const Navigation = observer(() => {
	const route = useAllRoutes()
	const createRef = useRef<HTMLDivElement | null>()
	const pipelinesRef = useRef<HTMLDivElement | null>()
	const spacesRef = useRef<HTMLDivElement | null>()
	const [activeLink, setActiveLink] = useState<string | null>(null)
	const [underlineStyle, setUnderlineStyle] = useState({})

	useEffect(() => {
		if (
			route?.name.startsWith("Onboarding") ||
			route?.name.startsWith("PipelinesCreate")
		) {
			setActiveLink(NavigationSectionsType.Create)
		} else if (
			route?.name.startsWith("PipelinesList") ||
			route?.name.startsWith("PipelinesDetail") ||
			route?.name.startsWith("DemoPipelinesDetail")
		) {
			setActiveLink(NavigationSectionsType.Pipelines)
		} else if (route?.name.startsWith("Spaces")) {
			setActiveLink(NavigationSectionsType.Spaces)
		} else {
			setActiveLink(null)
		}
	}, [route])

	useEffect(() => {
		switch (activeLink) {
			case NavigationSectionsType.Create: {
				setUnderlineStyle({
					left: createRef.current?.offsetLeft,
					width: createRef.current?.offsetWidth,
				})
				break
			}
			case NavigationSectionsType.Pipelines: {
				setUnderlineStyle({
					left: pipelinesRef.current?.offsetLeft,
					width: pipelinesRef.current?.offsetWidth,
				})
				break
			}
			case NavigationSectionsType.Spaces: {
				setUnderlineStyle({
					left: spacesRef.current?.offsetLeft,
					width: spacesRef.current?.offsetWidth,
				})
				break
			}
			default: {
				setUnderlineStyle({})
			}
		}
	}, [activeLink])

	return (
		<View align="center" direction="row">
			<View
				gap={4}
				align="center"
				direction="row"
				divided
				paddingStart={3}
			>
				<View.Item>
					<Link
						href={router.Home()}
						onClick={getLinkProps(router.Home()).onClick}
						attributes={{ ariaLabel: "Go to Home" }}
					>
						<Logo
							height="18px"
							style={{
								color: "var(--rs-color-foreground-primary)",
							}}
						/>
					</Link>
				</View.Item>

				<View.Item gapBefore={3}>
					<View className={css.container} gap={2} direction="row">
						<Button
							color="media"
							variant="ghost"
							as="a"
							ref={createRef}
							href={router.Onboarding()}
							onClick={getLinkProps(router.Onboarding()).onClick}
						>
							<Icon size={4} svg={<Plus />} />
							Create
						</Button>
						<Button
							color="media"
							variant="ghost"
							as="a"
							ref={pipelinesRef}
							href={router.PipelinesList()}
							onClick={
								getLinkProps(
									router.PipelinesList({ page: "1" }),
								).onClick
							}
						>
							<Icon size={4} svg={<Box />} />
							Pipelines
						</Button>
						<Button
							color="media"
							variant="ghost"
							as="a"
							ref={spacesRef}
							href={router.Spaces()}
							onClick={
								getLinkProps(router.Spaces({ page: "1" }))
									.onClick
							}
						>
							<Icon size={4} svg={<Cloud />} />
							Spaces
						</Button>
						<Button
							color="media"
							variant="ghost"
							as="a"
							attributes={{ target: "_blank" }}
							href="https://github.com/glassflow/glassflow-examples/tree/main/examples"
						>
							<Icon size={4} svg={<GitHub />} />
							Examples
						</Button>
						<Divider
							className={css.line}
							attributes={{ style: underlineStyle }}
						/>
					</View>
				</View.Item>
			</View>

			<View direction="row" gap={3} justify="end" grow>
				<DropdownMenu position="bottom-end">
					<DropdownMenu.Trigger>
						{(attributes) => {
							return (
								<Observer>
									{() => {
										let content: string | React.JSX.Element

										if (appStore.auth.isAuthenticated) {
											content = appStore.auth.profile.name
										} else {
											content = (
												<Skeleton
													width={25}
													height={4}
												/>
											)
										}

										return (
											<View>
												<Button
													fullWidth
													variant="outline"
													rounded
													color="neutral"
													attributes={{
														...attributes,
														ariaLabel: "User Menu",
													}}
													icon={User}
												>
													{content}
												</Button>
											</View>
										)
									}}
								</Observer>
							)
						}}
					</DropdownMenu.Trigger>
					<DropdownMenu.Content>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								as="a"
								href={router.Profile()}
								onClick={getLinkProps(router.Profile()).onClick}
								selected={getLinkProps(router.Profile()).active}
								icon={User}
							>
								Profile
							</DropdownMenu.Item>
							<DropdownMenu.Item
								as="a"
								attributes={{ target: "_blank" }}
								href="https://docs.glassflow.dev"
								icon={BookOpen}
								endSlot={<ExternalLink size={12} />}
							>
								Documentation
							</DropdownMenu.Item>
							{/* <DropdownMenu.Item
								as="a"
								href={router.Settings()}
								onClick={
									getLinkProps(router.Settings()).onClick
								}
								selected={
									getLinkProps(router.Settings()).active
								}
								icon={Settings}
							>
								Settings
							</DropdownMenu.Item> */}
						</DropdownMenu.Section>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								as="a"
								href="https://join.slack.com/t/glassflowhub/shared_invite/zt-2g3s6nhci-bb8cXP9g9jAQ942gHP5tqg"
								attributes={{ target: "_blank" }}
								icon={Slack}
								endSlot={<ExternalLink size={12} />}
							>
								Join Slack
							</DropdownMenu.Item>
							<DropdownMenu.Item
								as="a"
								attributes={{ target: "_blank" }}
								href={`mailto:help@glassflow.dev?subject=User Feedback | Web v${APP_VERSION}`}
								icon={Mail}
								onClick={() => {
									Fathom.trackEvent("Header:Send Feedback")
								}}
								endSlot={<ExternalLink size={12} />}
							>
								<Text>Send Feedback</Text>
								<Text color="neutral-faded" variant="caption-2">
									help@glassflow.dev
								</Text>
							</DropdownMenu.Item>
						</DropdownMenu.Section>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								as="a"
								onClick={() => {
									window._hsp = window._hsp || []
									window._hsp.push(["showBanner"])
								}}
							>
								Cookie Preferences
							</DropdownMenu.Item>
							<DropdownMenu.Item
								as="a"
								attributes={{ target: "_blank" }}
								href="https://glassflow.dev/privacy-policy"
								endSlot={<ExternalLink size={12} />}
							>
								Privacy Policy
							</DropdownMenu.Item>
						</DropdownMenu.Section>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								color="critical"
								onClick={() => {
									appStore.logout()
								}}
								icon={LogOut}
							>
								Logout
							</DropdownMenu.Item>
						</DropdownMenu.Section>
					</DropdownMenu.Content>
				</DropdownMenu>

				{featureFlagNotificationsCenter() ? (
					<NotificationCenter />
				) : null}
			</View>
		</View>
	)
})
