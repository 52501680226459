import * as Fathom from "fathom-client"
import { applySnapshot, cast, flow, getSnapshot, types } from "mobx-state-tree"

import {
	AccessTokenModel,
	LogModel,
	PipelineModel,
	api,
	fetchLatestSchema,
	postGenerateCustom,
} from "#api"

// #region AccessTokens

const AccessTokensModel = types
	.model("Access Tokens", {
		pipeline: types.maybe(types.reference(PipelineModel)),
		tokens: types.array(AccessTokenModel),
		isFetchingTokens: types.optional(types.boolean, false),
	})
	.actions((self) => {
		const getTokens = flow(function* () {
			if (self.pipeline === undefined) {
				return
			}

			self.isFetchingTokens = true

			let tokens: Awaited<ReturnType<typeof api.listAccessTokens>> =
				yield api.listAccessTokens({
					queries: {
						page_size: 1000,
					},
					params: {
						pipeline_id: self.pipeline.id,
					},
				})

			self.tokens = cast(tokens.access_tokens)

			self.isFetchingTokens = false
		})

		return {
			getTokens,
		}
	})
	.views((self) => {
		return {
			get hasTokens() {
				return self.tokens.length > 0
			},
		}
	})

// #endregion

// #region GlassFlow Sink

const GlassFlowSinkModel = types
	.model("GlassFlow Sink", {
		data: types.optional(
			types.array(
				types.model("SinkData", {
					text: types.string,
					id: types.string,
					date: types.frozen(),
				}),
			),
			[],
		),
		isFetchingData: types.optional(types.boolean, false),
	})
	.actions((self) => {
		const getGlassFlowSink = flow(function* (
			pipelineId: string,
			num: number,
		) {
			self.isFetchingData = true

			let data: Awaited<ReturnType<typeof api.previewEvents>> =
				yield api.previewEvents({
					queries: {
						num: num,
					},
					params: {
						pipeline_id: pipelineId,
						topic: "output",
					},
				})
			const prevState = [...self.data]

			if (data[0].req_id === prevState[0]?.id) {
				return
			} else {
				self.data = cast([
					...data.reverse().map((event) => ({
						text: JSON.stringify(event.response),
						id: event.req_id as string,
						date: new Date(),
					})),
					...prevState,
				])
			}

			self.isFetchingData = false
		})

		return {
			getGlassFlowSink,
		}
	})

// #endregion

// #region DataGenerator

const DataGeneratorModel = types.model("DataGenerator", {
	form: types.optional(
		types
			.model("DataGenerator Form", {
				dataGenerator: types.optional(
					types.array(
						types.model({
							key: types.string,
							value: types.string,
						}),
					),
					[{ key: "", value: "" }],
				),
				currentDataGenerator: types.optional(
					types.array(
						types.model({
							key: types.string,
							value: types.string,
						}),
					),
					[{ key: "", value: "" }],
				),
				isFetchingData: types.optional(types.boolean, false),
				isPostingData: types.optional(types.boolean, false),
			})
			.actions((self) => {
				const getData = flow(function* (pipelineId: string) {
					self.isFetchingData = true

					let data = yield fetchLatestSchema(pipelineId)

					self.currentDataGenerator = cast(data.generators)
					self.dataGenerator = cast(data.generators)

					self.isFetchingData = false
				})

				const postData = flow(function* (
					pipelineId: string,
					pipelineAccessToken: string,
				) {
					try {
						self.isPostingData = true

						let data = yield postGenerateCustom({
							pipeline: {
								pipeline_id: pipelineId,
								pipeline_access_token: pipelineAccessToken,
							},
							generator: {
								generator_type: "custom",
								generators: getSnapshot(
									self.dataGenerator,
								).filter(
									(data) =>
										data.key.length > 0 &&
										data.value.length > 0,
								),
							},
						})
						self.currentDataGenerator = cast(
							JSON.parse(data.generator || "").generators,
						)
						self.dataGenerator = cast(
							JSON.parse(data.generator || "").generators,
						)
						self.isPostingData = false

						return true
					} catch (error) {
						self.isPostingData = false
						console.log(error)

						return false
					}
				})

				return {
					getData,
					postData,
					setDataGenerator(
						dataGenerator: Array<{
							key: string
							value: string
						}> | null,
					) {
						if (dataGenerator === null) {
							self.dataGenerator = cast([
								{
									key: "",
									value: "",
								},
							])
						} else {
							self.dataGenerator = cast(dataGenerator)
						}
					},
					setCurrentDataGenerator(
						dataGenerator: Array<{
							key: string
							value: string
						}> | null,
					) {
						if (dataGenerator === null) {
							self.currentDataGenerator = cast([
								{
									key: "",
									value: "",
								},
							])
						} else {
							self.currentDataGenerator = cast(dataGenerator)
						}
					},
					addData() {
						self.dataGenerator = cast([
							...self.dataGenerator,
							{ key: "", value: "" },
						])
					},
					setDataName(index: number, key: string) {
						let clone = self.dataGenerator.slice(0)
						clone[index].key = key
						self.dataGenerator = cast(clone)
					},
					setDataValue(index: number, value: string) {
						let clone = self.dataGenerator.slice(0)
						clone[index].value = value
						self.dataGenerator = cast(clone)
					},
					removeData(index: number) {
						if (self.dataGenerator.length > 1) {
							let clone = self.dataGenerator.slice(0)
							clone.splice(index, 1)
							self.dataGenerator = cast(clone)
						} else {
							self.dataGenerator = cast([
								{
									key: "",
									value: "",
								},
							])
						}
					},
					discardData() {
						self.dataGenerator = cast(
							getSnapshot(self.currentDataGenerator),
						)
					},
				}
			})
			.views((self) => {
				return {
					get hasChanges() {
						return (
							JSON.stringify(getSnapshot(self.dataGenerator)) !==
							JSON.stringify(
								getSnapshot(self.currentDataGenerator),
							)
						)
					},
				}
			}),
		{},
	),
})

// #endregion

// #region Transformer

const TransformerModel = types
	.model("Transformer", {
		form: types.optional(
			types
				.model("Form", {
					currentHandler: types.optional(types.string, ""),
					currentRequirements: types.optional(types.string, ""),
					handler: types.optional(
						types.model("file", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					requirements: types.optional(
						types.model("file", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					environmentVariables: types.optional(
						types.array(
							types.model({
								name: types.string,
								value: types.string,
							}),
						),
						[{ name: "", value: "" }],
					),
					currentEnvironmentVariables: types.optional(
						types.array(
							types.model({
								name: types.string,
								value: types.string,
							}),
						),
						[{ name: "", value: "" }],
					),
				})
				.actions((self) => {
					return {
						validate() {
							if (
								self.handler.value === "" ||
								self.handler.value === undefined
							) {
								self.handler.hasError = true
								self.handler.errorMessage =
									"This field is required"
							} else {
								// Parse the Editor contents for a top level definition
								// of a function with the name `handler`
								let hasHandlerFunction = false

								const lines = self.handler.value.split("\n")

								for (let line of lines) {
									if (line.startsWith("def")) {
										if (
											line
												.slice(3)
												.trim()
												.startsWith("handler") &&
											line
												.slice(3)
												.trim()
												.slice(7)
												.trim()
												.startsWith("(")
										) {
											hasHandlerFunction = true
											break
										}
									}
								}

								if (!hasHandlerFunction) {
									self.handler.hasError = true
									self.handler.errorMessage =
										"You need to define the `handler` function."
								}
							}
						},
						setRequirements(requirements: string) {
							self.requirements.value = requirements
							self.requirements.hasError = false
						},
						setCurrentRequirements(requirements: string) {
							self.currentRequirements = requirements
						},
						setHandler(handler: string) {
							self.handler.value = handler
							self.handler.hasError = false
						},
						setCurrentHandler(handler: string) {
							self.currentHandler = handler
						},
						setEnvironmentVariables(
							environmentVariables: Array<{
								name: string
								value: string
							}> | null,
						) {
							if (environmentVariables === null) {
								self.environmentVariables = cast([
									{ name: "", value: "" },
								])
							} else {
								self.environmentVariables =
									cast(environmentVariables)
							}
						},
						setCurrentEnvironmentVariables(
							environmentVariables: Array<{
								name: string
								value: string
							}> | null,
						) {
							if (environmentVariables === null) {
								self.currentEnvironmentVariables = cast([
									{ name: "", value: "" },
								])
							} else {
								self.currentEnvironmentVariables =
									cast(environmentVariables)
							}
						},
					}
				})
				.views((self) => {
					return {
						get hasError() {
							return self.handler.hasError
						},

						get hasChanges() {
							return (
								self.handler.value !== self.currentHandler ||
								self.requirements.value !==
									self.currentRequirements ||
								JSON.stringify(
									getSnapshot(self.environmentVariables),
								) !==
									JSON.stringify(
										getSnapshot(
											self.currentEnvironmentVariables,
										),
									)
							)
						},
					}
				}),
			{},
		),
		editorSettings: types.optional(
			types
				.model({
					showInvisibles: types.optional(types.boolean, false),
					softWrap: types.optional(types.boolean, true),
				})
				.actions((self) => {
					return {
						setShowInvisibles(value: boolean) {
							self.showInvisibles = value
						},

						setSoftWrap(value: boolean) {
							self.softWrap = value
						},
					}
				}),
			{},
		),
	})
	.actions((self) => {
		const fetchSourceFiles = flow(function* (pipelineId: string) {
			const result: Awaited<ReturnType<typeof api.getArtifact>> =
				yield api.getArtifact({
					params: {
						pipeline_id: pipelineId,
					},
				})

			if (result.files.length > 0) {
				self.form.setCurrentHandler(result.files[0].content)
				self.form.setHandler(result.files[0].content)
			} else {
				self.form.setCurrentHandler("")
				self.form.setHandler("")
			}

			if (result.files.length > 1) {
				const requirements = result.files[1].content

				self.form.setCurrentRequirements(requirements)
				self.form.setRequirements(requirements)
			} else {
				self.form.setCurrentRequirements("")
				self.form.setRequirements("")
			}
		})

		return {
			fetchSourceFiles,
		}
	})

// #endregion

// #region Logs

const LogsModel = types
	.model("Logs", {
		pipeline: types.maybe(types.reference(PipelineModel)),
		cursor: types.maybe(
			types.model("Cursor", {
				cursor: types.string,
				originalCursorTime: types.number,
			}),
		),
		logs: types.array(LogModel),
		fullscreenLogIndex: types.maybe(types.number),
		fetch: types.optional(
			types.union(
				types.model({ isFetching: types.literal(false) }),
				types.model({
					isFetching: types.literal(true),
					fetchingStyle: types.union(
						types.literal("filterChange"),
						types.literal("navigation"),
						types.literal("nextPage"),
						types.literal("autoRefresh"),
					),
				}),
			),
			{ isFetching: false },
		),
		lastFetch: types.optional(types.maybeNull(types.number), null),
		lastFetchWasForced: types.optional(types.boolean, false),
		filters: types.optional(
			types
				.model({
					severityLevel: types.optional(
						types.union(
							types.literal(100),
							types.literal(200),
							types.literal(400),
							types.literal(500),
						),
						200,
					),
					timeframe: types.optional(
						types.union(
							types.literal("oneHour"),
							types.literal("sixHours"),
							types.literal("oneDay"),
							types.literal("sevenDays"),
						),
						"oneHour",
					),
				})
				.actions((self) => {
					return {
						setSeverityLevel(level: 100 | 200 | 400 | 500) {
							Fathom.trackEvent(
								"Pipeline:Logs Filter:Severity Set",
							)
							Fathom.trackEvent(
								`Pipeline:Logs Filter:Severity Set=${level}`,
							)
							self.severityLevel = level
						},
						setTimeframe(
							timeframe:
								| "oneHour"
								| "sixHours"
								| "oneDay"
								| "sevenDays",
						) {
							Fathom.trackEvent(
								"Pipeline:Logs Filter:Timeframe Set",
							)
							Fathom.trackEvent(
								`Pipeline:Logs Filter:Timeframe Set=${timeframe}`,
							)
							self.timeframe = timeframe
						},
					}
				}),
			{},
		),
	})
	.actions((self) => {
		const getLogs = flow(function* (options: {
			fetchingStyle:
				| "filterChange"
				| "navigation"
				| "nextPage"
				| "autoRefresh"
				| "forcedRefresh"
			pageSize?: number
		}) {
			if (self.pipeline === undefined) {
				return
			}

			options.pageSize ??= 15

			let startTime: string | undefined
			let endTimeTimestamp: number

			if (options.fetchingStyle === "nextPage") {
				if (self.cursor === undefined || self.cursor.cursor === "") {
					return
				} else {
					endTimeTimestamp = self.cursor.originalCursorTime
				}
			} else if (
				options.fetchingStyle === "autoRefresh" ||
				options.fetchingStyle === "forcedRefresh"
			) {
				self.cursor = undefined
				endTimeTimestamp = Date.now()
			} else {
				self.cursor = undefined
				self.logs = cast([])
				endTimeTimestamp = Date.now()
			}

			if (options.fetchingStyle === "forcedRefresh") {
				self.lastFetchWasForced = true
			} else {
				self.lastFetchWasForced = false
			}

			// #region Date Logic
			if (self.filters.timeframe === "oneHour") {
				startTime = new Date(
					endTimeTimestamp - 1000 * 60 * 60,
				).toISOString()
			} else if (self.filters.timeframe === "sixHours") {
				startTime = new Date(
					endTimeTimestamp - 1000 * 60 * 60 * 6,
				).toISOString()
			} else if (self.filters.timeframe === "oneDay") {
				startTime = new Date(
					endTimeTimestamp - 1000 * 60 * 60 * 24,
				).toISOString()
			} else {
				startTime = new Date(
					endTimeTimestamp - 1000 * 60 * 60 * 24 * 7,
				).toISOString()
			}
			// #endregion

			self.fetch = {
				isFetching: true,
				fetchingStyle:
					options.fetchingStyle === "forcedRefresh"
						? "autoRefresh"
						: options.fetchingStyle,
			}

			let result: Awaited<ReturnType<typeof api.getFunctionLogs>> =
				yield api.getFunctionLogs({
					queries: {
						page_size: options.pageSize,
						page_token: self.cursor?.cursor,
						severity_code: self.filters.severityLevel,
						start_time: startTime,
						end_time: new Date(endTimeTimestamp).toISOString(),
					},
					params: {
						pipeline_id: self.pipeline.id,
					},
				})

			if (self.cursor === undefined) {
				self.logs = cast([])
				self.cursor = {
					cursor: result.next,
					originalCursorTime: endTimeTimestamp,
				}
			} else {
				self.cursor.cursor = result.next
			}

			if (options.fetchingStyle === "autoRefresh") {
				self.logs = cast(result.logs.reverse())
			} else {
				self.logs = cast([...result.logs.reverse(), ...self.logs])
			}

			self.lastFetch = Date.now()

			self.fetch = { isFetching: false }
		})

		return {
			getLogs,
			showLogFullScreen(logIndex: number) {
				Fathom.trackEvent("Pipeline:Log Enter Fullscreen")
				self.fullscreenLogIndex = logIndex
				document.body.classList.add("preventBodyScroll")
			},
			closeFullScreen() {
				document.body.classList.remove("preventBodyScroll")
				self.fullscreenLogIndex = undefined
			},
		}
	})
	.views((self) => {
		return {
			get hasNoLogs() {
				return self.logs.length === 0
			},
			get hasLoadedFinalPage() {
				return self.cursor && self.cursor.cursor === ""
			},
			get hasMorePages() {
				return self.cursor && self.cursor.cursor !== ""
			},
		}
	})

// #endregion

// #region PipelineDetailPage

export const DemoPipelineDetailPage = types
	.model("DemoPipelineDetailPage", {
		pipeline: types.maybe(PipelineModel),
		accessTokens: types.optional(AccessTokensModel, {}),
		logs: types.optional(LogsModel, {}),
		transformer: types.optional(TransformerModel, {}),
		dataGenerator: types.optional(DataGeneratorModel, {}),
		glassFlowSink: types.optional(GlassFlowSinkModel, {}),
		onboardingTips: types.optional(
			types
				.model("OnboardingTips", {
					pipelineId: types.optional(types.string, ""),
					yourFirstPipeline: types.optional(types.boolean, false),
					mergeEmail: types.optional(types.boolean, false),
					seeAppliedChanges: types.optional(types.boolean, false),
					clickAddGenerator: types.optional(types.boolean, false),
					selectValueName: types.optional(types.boolean, false),
					dataWithNewSchema: types.optional(types.boolean, false),
					logsUpdates: types.optional(types.boolean, false),
					source: types.optional(types.boolean, false),
					sink: types.optional(types.boolean, false),
				})
				.actions((self) => ({
					saveToLocalStorage(): void {
						const pipelineId = self.pipelineId

						const onboardingData: Array<{
							pipelineId: string
							popups: Record<string, boolean>
						}> = JSON.parse(
							localStorage.getItem("onboardingTips") || "[]",
						)

						const pipelineData = onboardingData.find(
							(item) => item.pipelineId === pipelineId,
						) || { pipelineId, popups: {} }

						const snapshot = { ...getSnapshot(self) } as Record<
							string,
							unknown
						>
						snapshot.pipelineId = undefined

						pipelineData.popups = {
							...pipelineData.popups,
							...(snapshot as Record<string, boolean>),
						}

						const updatedData = onboardingData.filter(
							(item) => item.pipelineId !== pipelineId,
						)
						updatedData.push(pipelineData)

						localStorage.setItem(
							"onboardingTips",
							JSON.stringify(updatedData),
						)
					},
					loadFromLocalStorage(pipelineId: string | undefined): void {
						if (!pipelineId) return

						const onboardingData: Array<{
							pipelineId: string
							popups: Record<string, boolean>
						}> = JSON.parse(
							localStorage.getItem("onboardingTips") || "[]",
						)

						let pipelineData = onboardingData.find(
							(item) => item.pipelineId === pipelineId,
						)

						if (!pipelineData) {
							const snapshot = { ...getSnapshot(self) } as Record<
								string,
								unknown
							>
							snapshot.pipelineId = undefined
							pipelineData = {
								pipelineId,
								popups: snapshot as Record<string, boolean>,
							}
							onboardingData.push(pipelineData)
							localStorage.setItem(
								"onboardingTips",
								JSON.stringify(onboardingData),
							)
						} else {
							applySnapshot(self, {
								...self,
								...pipelineData.popups,
							})
						}
					},
					setOnboardingTip(key: keyof typeof self, value: boolean) {
						const snapshot = getSnapshot(self)
						applySnapshot(self, { ...snapshot, [key]: value })
						this.saveToLocalStorage()
					},
					reset() {
						self.yourFirstPipeline = false
						self.mergeEmail = false
						self.seeAppliedChanges = false
						self.clickAddGenerator = false
						self.selectValueName = false
						self.dataWithNewSchema = false
						self.logsUpdates = false
						self.source = false
						self.sink = false
					},
				})),
			{},
		),
		isSaving: types.optional(types.boolean, false),
	})
	.actions((self) => {
		return {
			getPipeline: flow(function* (options: {
				pipelineId: string
			}) {
				let pipeline: Awaited<ReturnType<typeof api.getPipeline>> =
					yield api.getPipeline({
						params: {
							pipeline_id: options.pipelineId,
						},
					})

				self.accessTokens.pipeline = undefined
				self.logs.pipeline = undefined

				self.pipeline = cast(pipeline)
				self.onboardingTips.pipelineId = cast(pipeline.id)

				if (self.pipeline) {
					self.accessTokens.pipeline = self.pipeline
					self.accessTokens.getTokens()
					self.dataGenerator.form.getData(self.pipeline.id)
					self.transformer.fetchSourceFiles(self.pipeline.id)

					if (self.pipeline.environments !== null) {
						self.transformer.form.setEnvironmentVariables(
							getSnapshot(self.pipeline.environments),
						)
						self.transformer.form.setCurrentEnvironmentVariables(
							getSnapshot(self.pipeline.environments),
						)
					} else {
						self.transformer.form.setEnvironmentVariables(null)
						self.transformer.form.setCurrentEnvironmentVariables(
							null,
						)
					}

					self.logs.pipeline = self.pipeline
					self.logs.filters = cast({})
					self.logs.getLogs({ fetchingStyle: "navigation" })
				}
			}),

			saveTransformer: flow(function* (callback: () => Promise<void>) {
				if (self.pipeline?.id) {
					self.transformer.form.validate()

					if (!self.transformer.form.hasError) {
						self.isSaving = true

						const result: Awaited<
							ReturnType<typeof api.uploadFunctionArtifacts>
						> = yield api.uploadFunctionArtifacts(
							{
								file: new File(
									[self.transformer.form.handler.value],
									"handler.py",
									{
										type: "text/plain",
									},
								),
								...(self.transformer.form.requirements
									.value && {
									requirementsTxt: new File(
										[
											self.transformer.form.requirements
												.value,
										],
										"requirements.txt",
										{
											type: "text/plain",
										},
									),
								}),
							},
							{
								params: {
									pipeline_id: self.pipeline.id,
								},
							},
						)

						self.transformer.form.setCurrentHandler(
							self.transformer.form.handler.value,
						)

						self.transformer.form.setCurrentRequirements(
							self.transformer.form.requirements.value,
						)

						self.transformer.form.setCurrentEnvironmentVariables(
							getSnapshot(
								self.transformer.form.environmentVariables,
							),
						)

						yield callback()

						self.isSaving = false

						return result
					}
				}
			}),

			refreshPipeline: flow(function* () {
				if (self.pipeline?.id) {
					let pipeline: Awaited<ReturnType<typeof api.getPipeline>> =
						yield api.getPipeline({
							params: {
								pipeline_id: self.pipeline.id,
							},
						})

					self.pipeline = cast(pipeline)
				}
			}),
		}
	})
	.views((self) => {
		return {
			get sourceConnectorType(): string {
				if (self.pipeline?.metadata?.sourceConnector?.type) {
					return self.pipeline.metadata.sourceConnector.type
				} else if (self.pipeline?.source_connector?.kind) {
					return self.pipeline.source_connector.kind
				} else {
					return "sdk"
				}
			},
		}
	})

// #endregion

export const demoPipelineDetailPageStore = DemoPipelineDetailPage.create()
