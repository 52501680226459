import { observer } from "mobx-react"
import { types } from "mobx-state-tree"

import { RenamePipelineModal } from "./RenamePipeline/modal"
import { RenamePipelineModalModel } from "./RenamePipeline/modal.model"

import { UploadFunctionFileModal } from "./UploadFunctionFile/modal"
import { UploadFunctionFileModalModel } from "./UploadFunctionFile/modal.model"

import { DeletePipelineModal } from "./DeletePipeline/modal"
import { DeletePipelineModalModel } from "./DeletePipeline/modal.model"

import { CreateAccessTokenModal } from "./CreateAccessToken/modal"
import { CreateAccessTokenModalModel } from "./CreateAccessToken/modal.model"

import { DeleteAccessTokenModal } from "./DeleteAccessToken/modal"
import { DeleteAccessTokenModalModel } from "./DeleteAccessToken/modal.model"

import { RenameAccessTokenModal } from "./RenameAccessToken/modal"
import { RenameAccessTokenModalModel } from "./RenameAccessToken/modal.model"

import { CreateSpaceModal } from "./CreateSpace/modal"
import { CreateSpaceModalModel } from "./CreateSpace/modal.model"

import { RenameSpaceModal } from "./RenameSpace/modal"
import { RenameSpaceModalModel } from "./RenameSpace/modal.model"

import { DeleteSpaceModal } from "./DeleteSpace/modal"
import { DeleteSpaceModalModel } from "./DeleteSpace/modal.model"

import { EditEnvironmentVariablesModal } from "./EditEnvironmentVariables/modal"
import { EditEnvironmentVariablesModalModel } from "./EditEnvironmentVariables/modal.model"

import { TransformModal } from "./Transform/modal"
import { TransformModalModel } from "./Transform/modal.model"

import { SourceModal } from "./Source/modal"
import { SourceModalModel } from "./Source/modal.model"

import { SinkModal } from "./Sink/modal"
import { SinkModalModel } from "./Sink/modal.model"

import { OnboardingTipModal } from "./OnboardingTip/modal"
import { OnboardingTipModalModel } from "./OnboardingTip/modal.model"

import { EditTransformModal } from "./EditTransform/modal"
import { EditTransformModalModel } from "./EditTransform/modal.model"

import { DemoSourceModal } from "./DemoSource/modal"
import { DemoSourceModalModel } from "./DemoSource/modal.model"

import { DemoSinkModal } from "./DemoSink/modal"
import { DemoSinkModalModel } from "./DemoSink/modal.model"

const ModalsModel = types.model("ModalsStore", {
	renamePipeline: RenamePipelineModalModel,
	uploadFunctionFile: UploadFunctionFileModalModel,
	deletePipeline: DeletePipelineModalModel,
	createAccessToken: CreateAccessTokenModalModel,
	deleteAccessToken: DeleteAccessTokenModalModel,
	renameAccessToken: RenameAccessTokenModalModel,
	createSpace: CreateSpaceModalModel,
	renameSpace: RenameSpaceModalModel,
	deleteSpace: DeleteSpaceModalModel,
	editEnvironmentVariables: EditEnvironmentVariablesModalModel,
	transform: TransformModalModel,
	editTransform: EditTransformModalModel,
	source: SourceModalModel,
	demoSource: DemoSourceModalModel,
	sink: SinkModalModel,
	demoSink: DemoSinkModalModel,
	onboardingTip: OnboardingTipModalModel,
})

export const modalsStore = ModalsModel.create({
	renamePipeline: {},
	uploadFunctionFile: {},
	deletePipeline: {},
	createAccessToken: {},
	deleteAccessToken: {},
	renameAccessToken: {},
	createSpace: {},
	renameSpace: {},
	deleteSpace: {},
	editEnvironmentVariables: {},
	transform: {},
	editTransform: {},
	source: {},
	demoSource: {},
	sink: {},
	demoSink: {},
	onboardingTip: {},
})

export const Modals = observer(() => {
	return (
		<>
			<RenamePipelineModal />
			<UploadFunctionFileModal />
			<DeletePipelineModal />
			<CreateAccessTokenModal />
			<DeleteAccessTokenModal />
			<RenameAccessTokenModal />
			<CreateSpaceModal />
			<RenameSpaceModal />
			<DeleteSpaceModal />
			<EditEnvironmentVariablesModal />
			<TransformModal />
			<EditTransformModal />
			<SourceModal />
			<DemoSourceModal />
			<SinkModal />
			<DemoSinkModal />
			<OnboardingTipModal />
		</>
	)
})
