import { observer } from "mobx-react"
import { Modal, View } from "reshaped"

import { useLocation } from "@swan-io/chicane"
import { useEffect } from "preact/hooks"
import { modalsStore } from "#modals"
import { ExpandableTabTopPanel } from "../../components/PipelineCreation/ExpandableTab/component"
import { PipelineCreateType } from "../../enums"
import { pipelineCreatePageStore } from "../../pages/PipelineCreate/page.model"
import { DataSource } from "../../pages/PipelineCreate/tabs/dataSource"
import { DataSource as DataSourceEmailEncryption } from "../../pages/PipelineCreateEmailEncryption/tabs/dataSource"
import { getCreatePipelineFunction, getPipelineCreateType } from "../../utils"
import css from "./modal.module.css"

export const getView = () => {
	switch (getPipelineCreateType()) {
		case PipelineCreateType.EmailEncryption:
			return <DataSourceEmailEncryption />
		case PipelineCreateType.Blank:
			return <DataSource />
		default:
			return <DataSource />
	}
}

export const SourceModal = observer(() => {
	const sourceModalStore = modalsStore.source
	const store = pipelineCreatePageStore
	const location = useLocation()
	const isBlank = getPipelineCreateType() === PipelineCreateType.Blank

	useEffect(() => {
		if (location.raw.path !== "/pipelines/create") {
			sourceModalStore.closeModal()
		}
	}, [location.raw.path, sourceModalStore.closeModal])

	return (
		<Modal
			className="modal"
			active={sourceModalStore.isOpen}
			padding={6}
			size="1024px"
			onClose={async () => {
				if (isBlank) {
					if (await store.isSourceFormValid()) {
						sourceModalStore.closeModal()
					}
				} else {
					sourceModalStore.closeModal()
					setTimeout(() => {
						document.body.style.overflow = ""
					}, 1000)
				}
			}}
			overlayClassName={css.overlay0}
			blurredOverlay
		>
			<ExpandableTabTopPanel
				title="Data Source"
				onClose={sourceModalStore.closeModal}
				onNextClick={() =>
					getCreatePipelineFunction(
						sourceModalStore.nextButtonFunctionId,
					)()
				}
				onPrevClick={() =>
					getCreatePipelineFunction(
						sourceModalStore.prevButtonFunctionId,
					)()
				}
				validate={store.isSourceFormValid}
				prevButtonName={sourceModalStore.prevButtonName}
				nextButtonName={sourceModalStore.nextButtonName}
				withClose={sourceModalStore.withClose}
			/>
			<View paddingTop={4}>{getView()}</View>
		</Modal>
	)
})
