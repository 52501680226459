import { observer } from "mobx-react"
import { Modal, View } from "reshaped"

import { useLocation } from "@swan-io/chicane"
import { useEffect } from "preact/hooks"
import { modalsStore } from "#modals"
import { ExpandableTabTopPanel } from "../../components/PipelineCreation/ExpandableTab/component"
import { PipelineCreateType } from "../../enums"
import { demoPipelineDetailPageStore } from "../../pages/DemoPipelineDetail/page.model"
import { Transform } from "../../pages/DemoPipelineDetail/tabs/transform"
import { getCreatePipelineFunction } from "../../utils"
import css from "./modal.module.css"

export const getModalName = (type: PipelineCreateType) => {
	switch (type) {
		case PipelineCreateType.EmailEncryption:
			return "E-mail Encryption"
		case PipelineCreateType.Blank:
			return "Function"
		default:
			return "Function"
	}
}

export const EditTransformModal = observer(() => {
	const transformModalStore = modalsStore.editTransform
	const store = demoPipelineDetailPageStore
	const location = useLocation()

	useEffect(() => {
		if (
			!location.raw.path.includes("demo/pipelines/") ||
			!location.raw.path.includes("pipelines/")
		) {
			transformModalStore.closeModal()
		}
	}, [location.raw.path, transformModalStore.closeModal])

	return (
		<Modal
			className="modal"
			active={transformModalStore.isOpen}
			padding={6}
			size="1024px"
			onClose={async () => transformModalStore.closeModal()}
			blurredOverlay
			overlayClassName={css.overlay0}
		>
			<ExpandableTabTopPanel
				title={`Transform: ${getModalName(store.pipeline?.metadata.type)}`}
				onClose={transformModalStore.closeModal}
				onNextClick={() =>
					getCreatePipelineFunction(
						transformModalStore.nextButtonFunctionId,
					)()
				}
				onPrevClick={() =>
					getCreatePipelineFunction(
						transformModalStore.prevButtonFunctionId,
					)()
				}
				validate={undefined}
				nextButtonLoading={transformModalStore.nextButtonLoading}
				prevButtonName={transformModalStore.prevButtonName}
				nextButtonName={transformModalStore.nextButtonName}
				withClose={transformModalStore.withClose}
			/>
			<View paddingTop={4}>
				<Transform />
			</View>
		</Modal>
	)
})
