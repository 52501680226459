import { observer } from "mobx-react"
import { Check, Trash2 } from "react-feather"
import {
	Button,
	Hidden,
	Select,
	Text,
	TextField,
	View,
	useToast,
} from "reshaped"
import { modalsStore } from "#modals"
import { DataGeneratorOptionsType } from "../../../enums"
import { demoPipelineDetailPageStore } from "../page.model"

const options = [
	{
		label: "Name",
		value: DataGeneratorOptionsType.name,
	},
	{
		label: "Text",
		value: DataGeneratorOptionsType.text,
	},
	{
		label: "Address",
		value: DataGeneratorOptionsType.address,
	},
	{
		label: "Phone number",
		value: DataGeneratorOptionsType.phone_number,
	},
	{
		label: "Job",
		value: DataGeneratorOptionsType.job,
	},
	{
		label: "Company",
		value: DataGeneratorOptionsType.company,
	},
	{
		label: "Country",
		value: DataGeneratorOptionsType.country,
	},
	{
		label: "City",
		value: DataGeneratorOptionsType.city,
	},
	{
		label: "Administrative Unit",
		value: DataGeneratorOptionsType.administrative_unit,
	},
	{
		label: "Zipcode",
		value: DataGeneratorOptionsType.zipcode,
	},
	{
		label: "User name",
		value: DataGeneratorOptionsType.user_name,
	},
	{
		label: "Password",
		value: DataGeneratorOptionsType.password,
	},
	{
		label: "SSN",
		value: DataGeneratorOptionsType.ssn,
	},
	{
		label: "Ipv4",
		value: DataGeneratorOptionsType.ipv4,
	},
	{
		label: "URL",
		value: DataGeneratorOptionsType.url,
	},
	{
		label: "UUID4",
		value: DataGeneratorOptionsType.uuid4,
	},
	{
		label: "Boolean",
		value: DataGeneratorOptionsType.boolean,
	},
	{
		label: "Currency Name",
		value: DataGeneratorOptionsType.currency_name,
	},
	{
		label: "Color name",
		value: DataGeneratorOptionsType.color_name,
	},
	{
		label: "Email",
		value: DataGeneratorOptionsType.company_email,
	},
]

export const DataGenerator = observer(() => {
	const toast = useToast()
	const store = demoPipelineDetailPageStore

	const saveData = async () => {
		if (
			await store.dataGenerator.form.postData(
				store.pipeline?.id || "",
				store.accessTokens.tokens[0].token,
			)
		) {
			const id = toast.show({
				color: "positive",
				timeout: 5000,
				icon: Check,
				title: "Data schema saved",
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
		}
	}

	return (
		<View>
			<View.Item columns={6}>
				<form onSubmit={() => {}}>
					<View gap={4}>
						<View gap={4}>
							<View gap={4} grow direction="row" paddingEnd={9}>
								<View.Item columns={6}>
									<Text>Value Name</Text>
								</View.Item>
								<View.Item columns={6}>
									<Text>Value Type</Text>
								</View.Item>
							</View>
						</View>
						{store.dataGenerator.form.dataGenerator.map(
							({ key, value }, index) => {
								return (
									// biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
									<View gap={4}>
										<View gap={1} direction="row">
											<View direction="row" grow gap={4}>
												<View.Item columns={6}>
													<TextField
														name={`dataName${index}`}
														placeholder="Name"
														disabled={index === 0}
														value={key}
														onChange={({
															value,
														}) => {
															store.dataGenerator.form.setDataName(
																index,
																value,
															)
														}}
														inputAttributes={{
															"data-1p-ignore": true,
														}}
													/>
												</View.Item>
												<View.Item columns={6}>
													<Select
														name={`dataValue${index}`}
														value={value}
														disabled={index === 0}
														options={options}
														onChange={({
															value,
														}) => {
															store.dataGenerator.form.setDataValue(
																index,
																value,
															)
														}}
													/>
												</View.Item>
											</View>
											{index === 0 ? (
												<View width={9} />
											) : (
												<Button
													icon={Trash2}
													color="neutral"
													variant="ghost"
													onClick={() => {
														store.dataGenerator.form.removeData(
															index,
														)
													}}
												/>
											)}
										</View>
									</View>
								)
							},
						)}

						<View.Item>
							<Button
								color="neutral"
								disabled={
									store.dataGenerator.form.isPostingData
								}
								variant="ghost"
								onClick={() => {
									store.dataGenerator.form.addData()

									setTimeout(
										() =>
											modalsStore.onboardingTip.openModal(
												"You can select different value types and name them.<br/><br/>After editing generator schema click <b>Save Changes</b> and visit <b>GlassFlow Sink</b> tab to display real-time changes.",
												"showedSelectValueName",
												store.onboardingTips
													.selectValueName,
												true,
												true,
											),
										500,
									)
								}}
							>
								Add Generator
							</Button>
						</View.Item>
					</View>

					<Hidden hide>
						<button type="submit" />
					</Hidden>
				</form>
				<View direction="row" gap={4} paddingTop={16}>
					<Button
						color="neutral"
						disabled={store.dataGenerator.form.isPostingData}
						onClick={() => store.dataGenerator.form.discardData()}
					>
						Discard
					</Button>
					<Button
						color="primary"
						loading={store.dataGenerator.form.isPostingData}
						onClick={() => saveData()}
					>
						Save Changes
					</Button>
				</View>
			</View.Item>
		</View>
	)
})
