import { observer } from "mobx-react"
import { Dismissible, Modal } from "reshaped"
import css from "./modal.module.css"

import { useLocation } from "@swan-io/chicane"
import classNames from "classnames"
import { useEffect, useRef } from "preact/hooks"
import { modalsStore } from "#modals"
import { useOutsideClick } from "../../hooks/useOutsideClick"
import { getCreatePipelineFunction } from "../../utils"

export const OnboardingTipModal = observer(() => {
	const modalStore = modalsStore.onboardingTip
	const location = useLocation()
	const ref = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		if (
			!modalStore.isTransparentOverlay ||
			modalsStore.demoSource.isOpen ||
			modalsStore.demoSink.isOpen ||
			modalsStore.editTransform.isOpen ||
			modalsStore.transform.isOpen ||
			modalsStore.source.isOpen ||
			modalsStore.sink.isOpen
		) {
			setTimeout(() => {
				document.body.style.overflow = "hidden"
			}, 500)
		} else {
			setTimeout(() => {
				document.body.style.overflow = ""
			}, 500)
		}
	}, [modalStore.isTransparentOverlay])

	useOutsideClick(ref, () => {
		modalStore.closeModal()
		getCreatePipelineFunction(modalStore.closeFunctionId)()
	})

	useEffect(() => {
		if (
			!location.raw.path.includes("demo/pipelines/") ||
			!location.raw.path.includes("pipelines/create/email-encryption")
		) {
			modalStore.closeModal()
		}
	}, [location.raw.path, modalStore.closeModal])

	return (
		<Modal
			attributes={{ ref: ref }}
			className={classNames(css.modal, {
				[css.bottom]: modalStore.isBottomAligned,
			})}
			active={modalStore.isOpen}
			padding={6}
			transparentOverlay={modalStore.isTransparentOverlay}
			overlayClassName={
				modalStore.isTransparentOverlay ? css.overlay0 : css.overlay25
			}
			size="540px"
			onClose={() => {
				modalStore.closeModal()
				getCreatePipelineFunction(modalStore.closeFunctionId)()
			}}
		>
			<Dismissible
				onClose={() => {
					modalStore.closeModal()
					getCreatePipelineFunction(modalStore.closeFunctionId)()
				}}
				closeAriaLabel="Close modal"
			>
				{/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
				<div dangerouslySetInnerHTML={{ __html: modalStore.text }} />
			</Dismissible>
		</Modal>
	)
})
