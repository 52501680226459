import { observer } from "mobx-react"
import { useEffect } from "preact/hooks"
import { View } from "reshaped"
import { SinkDataRow } from "../../../components/SinkDataRow/component"
import { demoPipelineDetailPageStore } from "../page.model"
import css from "./GlassFlowSink.module.css"

export const GlassFlowSink = observer(() => {
	const store = demoPipelineDetailPageStore

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		store.glassFlowSink.getGlassFlowSink(store.pipeline?.id || "", 20)
		const interval = setInterval(
			() =>
				store.glassFlowSink.getGlassFlowSink(
					store.pipeline?.id || "",
					1,
				),
			1000,
		)

		return () => clearInterval(interval)
	}, [])

	return (
		<View className={css.overflowWrapper}>
			<View className={css.wrapper}>
				{store.glassFlowSink.data.map((data) => (
					<SinkDataRow key={data.date} text={data.text} />
				))}
			</View>
		</View>
	)
})
