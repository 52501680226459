import { observer } from "mobx-react"
import { Modal, View } from "reshaped"

import { useLocation } from "@swan-io/chicane"
import { useEffect } from "preact/hooks"
import { modalsStore } from "#modals"
import { ExpandableTabTopPanel } from "../../components/PipelineCreation/ExpandableTab/component"
import { PipelineCreateType } from "../../enums"
import { pipelineCreatePageStore } from "../../pages/PipelineCreate/page.model"
import { Transform } from "../../pages/PipelineCreate/tabs/transform"
import { Transform as TransformEmailEncryption } from "../../pages/PipelineCreateEmailEncryption/tabs/transform"
import { getCreatePipelineFunction, getPipelineCreateType } from "../../utils"
import css from "./modal.module.css"

export const getView = () => {
	switch (getPipelineCreateType()) {
		case PipelineCreateType.EmailEncryption:
			return <TransformEmailEncryption />
		case PipelineCreateType.Blank:
			return <Transform />
		default:
			return <Transform />
	}
}
export const getModalName = () => {
	switch (getPipelineCreateType()) {
		case PipelineCreateType.EmailEncryption:
			return "E-mail Encryption"
		case PipelineCreateType.Blank:
			return "Function"
		default:
			return "Function"
	}
}

export const TransformModal = observer(() => {
	const transformModalStore = modalsStore.transform
	const store = pipelineCreatePageStore
	const location = useLocation()
	const isBlank = getPipelineCreateType() === PipelineCreateType.Blank

	useEffect(() => {
		if (location.raw.path !== "/pipelines/create") {
			transformModalStore.closeModal()
		}
	}, [location.raw.path, transformModalStore.closeModal])

	return (
		<Modal
			className="modal"
			active={transformModalStore.isOpen}
			padding={6}
			size="1024px"
			onClose={async () => {
				if (isBlank) {
					if (await store.isTransformerFormValid()) {
						transformModalStore.closeModal()
					}
				} else {
					transformModalStore.closeModal()
					getCreatePipelineFunction(
						transformModalStore.nextButtonFunctionId,
					)()
					setTimeout(() => {
						document.body.style.overflow = ""
					}, 1000)
				}
			}}
			blurredOverlay
			overlayClassName={css.overlay0}
		>
			<ExpandableTabTopPanel
				title={`Transform: ${getModalName()}`}
				onClose={transformModalStore.closeModal}
				onNextClick={() =>
					getCreatePipelineFunction(
						transformModalStore.nextButtonFunctionId,
					)()
				}
				onPrevClick={() =>
					getCreatePipelineFunction(
						transformModalStore.prevButtonFunctionId,
					)()
				}
				validate={
					getPipelineCreateType() === PipelineCreateType.Blank
						? store.isTransformerFormValid
						: undefined
				}
				prevButtonName={transformModalStore.prevButtonName}
				nextButtonName={transformModalStore.nextButtonName}
				withClose={transformModalStore.withClose}
			/>
			<View paddingTop={4}>{getView()}</View>
		</Modal>
	)
})
