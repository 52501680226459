import { observer } from "mobx-react"
import { Button, FormControl, Tabs, View } from "reshaped"

import { getSnapshot } from "mobx-state-tree"
import { Layers } from "react-feather"
import { modalsStore } from "#modals"
import { CodeEditor } from "../../../components/PipelineCreation/CodeEditor/component"
import { pipelineEmailEncryptionCreatePageStore } from "../page.model"

export const Transform = observer(() => {
	const store = pipelineEmailEncryptionCreatePageStore

	return (
		<View minHeight={140} gap={4}>
			<View direction="row" gap={3} align="end">
				<View grow justify="end" direction="row" gap={3}>
					<Button
						color="primary"
						icon={Layers}
						onClick={() => {
							modalsStore.editEnvironmentVariables.openModal(
								getSnapshot(
									store.transformerForm.environmentVariables,
								),
								async () => {},
								true,
							)
						}}
					>
						Environment Variables
					</Button>
				</View>
			</View>
			<FormControl>
				<Tabs>
					<Tabs.List>
						<Tabs.Item value="handler">handler.py</Tabs.Item>
						<Tabs.Item value="requirements">
							requirements.txt
						</Tabs.Item>
					</Tabs.List>
					<Tabs.Panel value="handler">
						<CodeEditor
							softWrap={store.editorSettings.softWrap}
							showInvisibles={store.editorSettings.showInvisibles}
							value={store.transformerForm.handler.value}
							minHeight="300px"
							height="465px"
							paddingTop={3}
							readOnly
						/>
					</Tabs.Panel>
					<Tabs.Panel value="requirements">
						<CodeEditor
							softWrap={store.editorSettings.softWrap}
							showInvisibles={store.editorSettings.showInvisibles}
							value={store.transformerForm.requirements.value}
							minHeight="300px"
							height="465px"
							paddingTop={3}
							readOnly
						/>
					</Tabs.Panel>
				</Tabs>
			</FormControl>
		</View>
	)
})
