import { Icon, RadioGroup, Text, View } from "reshaped"

import { GitHub } from "react-feather"
import Amazon from "../../../../public/icons/amazonSQS.svg?react"
import Google from "../../../../public/icons/googlePubSub.svg?react"
import Webhook from "../../../../public/icons/webhook.svg?react"
import { CardRadio } from "../../../components/PipelineCreation/CardRadio/component"

export const SourceOptions = [
	{ value: "dummy", name: "Dummy Data Generator" },
	{ value: "sdk", name: "SDK", icon: <Icon size={5} svg={<GitHub />} /> },
	{
		value: "webhook",
		name: "Webhook",
		icon: (
			<Webhook
				height="24px"
				style={{
					color: "var(--rs-color-border-neutral-faded)",
				}}
			/>
		),
	},
	{
		value: "amazon_sqs",
		name: "Amazon SQS",
		icon: (
			<Amazon
				height="24px"
				style={{
					color: "var(--rs-color-border-neutral-faded)",
				}}
			/>
		),
	},
	{
		value: "google_pubsub",
		name: "Google Pub/Sub",
		icon: (
			<Google
				height="24px"
				style={{
					color: "var(--rs-color-border-neutral-faded)",
				}}
			/>
		),
	},
]

export const DataSource = () => {
	return (
		<View minHeight={140}>
			<View paddingBottom={6} direction="row" gap={3} align="center">
				<Text variant="body-1">
					We generate dummy data so you can test pipelines quickly.
				</Text>
			</View>
			<View direction="row" align="stretch" gap={4}>
				<RadioGroup name="source">
					{SourceOptions.map((option) => (
						<CardRadio
							key={option.value}
							isSelected={option.value === SourceOptions[0].value}
							value={option.value}
							name={option.name}
							isDisabled={option.value !== SourceOptions[0].value}
							icon={option.icon}
						/>
					))}
				</RadioGroup>
			</View>
		</View>
	)
}
