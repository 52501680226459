import { cast, flow, getSnapshot, types } from "mobx-state-tree"

export type EnvironmentVariable = { name: string; value: string }

let updateCallback: (
	newEnvironmentVariables: Array<EnvironmentVariable>,
) => Promise<void>

export const EditEnvironmentVariablesModalModel = types
	.model("EditEnvironmentVariablesModal", {
		isSaving: types.optional(types.boolean, false),
		isOpen: types.optional(types.boolean, false),
		isDemo: types.optional(types.boolean, false),
		environmentVariables: types.array(
			types.model({
				name: types.optional(types.string, ""),
				value: types.optional(types.string, ""),
			}),
		),
		error: types.maybe(types.frozen()),
	})
	.actions((self) => {
		return {
			openModal(
				environmentVariables: Array<EnvironmentVariable>,
				callback: (
					newEnvironmentVariables: Array<EnvironmentVariable>,
				) => Promise<void> = async () => {},
				isDemo?: boolean,
			) {
				updateCallback = callback

				if (environmentVariables.length === 0) {
					self.environmentVariables = cast([{ name: "", value: "" }])
				} else {
					self.environmentVariables = cast(environmentVariables)
				}
				self.isDemo = isDemo || false
				self.isOpen = true
			},
			closeModal() {
				self.isOpen = false
			},
			addVariable() {
				self.environmentVariables = cast([
					...self.environmentVariables,
					{ name: "", value: "" },
				])
			},
			setVariableKey(index: number, key: string) {
				let clone = self.environmentVariables.slice(0)
				clone[index].name = key
				self.environmentVariables = cast(clone)
			},
			setVariableValue(index: number, value: string) {
				let clone = self.environmentVariables.slice(0)
				clone[index].value = value
				self.environmentVariables = cast(clone)
			},
			removeVariable(index: number) {
				if (self.environmentVariables.length > 1) {
					let clone = self.environmentVariables.slice(0)
					clone.splice(index, 1)
					self.environmentVariables = cast(clone)
				} else {
					self.environmentVariables = cast([
						{
							name: "",
							value: "",
						},
					])
				}
			},
			closeModalAndReturnVariables: flow(function* () {
				try {
					self.isSaving = true

					try {
						yield updateCallback(
							getSnapshot(self.environmentVariables),
						)
					} catch (error) {}

					self.isOpen = false

					yield new Promise((resolve) => setTimeout(resolve, 200))

					self.isSaving = false

					return true
				} catch (error) {
					self.isSaving = false
					self.error = error

					return false
				}
			}),
		}
	})
