import { observer } from "mobx-react"
import { Copy, Plus, X } from "react-feather"
import {
	Button,
	Card,
	Skeleton,
	Text,
	View,
	useTheme,
	useToast,
} from "reshaped"

import { modalsStore } from "#modals"

import { pipelineDetailPageStore } from "../page.model"
import css from "./Details.module.css"

const TextSkeleton = () => <Skeleton height={2} width={40} />

export const Details = observer(() => {
	const toast = useToast()
	const { colorMode } = useTheme()

	return (
		<View gap={4}>
			<Card padding={0} height={114} className={css.card}>
				<View padding={6} gap={8} direction="row">
					<View.Item columns={6}>
						<Text variant="body-3" color="neutral-faded">
							Created on
						</Text>
						<Text variant="body-3" weight="medium">
							{pipelineDetailPageStore.pipeline?.created_at ? (
								Intl.DateTimeFormat("en", {
									dateStyle: "long",
									timeStyle: "medium",
								}).format(
									Date.parse(
										pipelineDetailPageStore.pipeline
											.created_at,
									),
								)
							) : (
								<TextSkeleton />
							)}
						</Text>
					</View.Item>
					<View.Item columns={6}>
						<Text variant="body-3" color="neutral-faded">
							Space
						</Text>
						<Text variant="body-3" weight="medium">
							{pipelineDetailPageStore.pipeline?.space_name ? (
								pipelineDetailPageStore.pipeline.space_name
							) : (
								<TextSkeleton />
							)}
						</Text>
					</View.Item>
					<View.Item columns={6}>
						<View gap={1}>
							<Text variant="body-3" color="neutral-faded">
								Pipeline ID
							</Text>
							<Button
								variant="outline"
								endIcon={Copy}
								className={css.credential}
								onClick={async () => {
									if (pipelineDetailPageStore.pipeline) {
										await navigator.clipboard.writeText(
											pipelineDetailPageStore.pipeline.id,
										)

										const id = toast.show({
											color: "neutral",
											icon: Copy,
											title: "Copied Pipeline ID",
											actionsSlot: (
												<Button
													variant="ghost"
													icon={X}
													onClick={() =>
														toast.hide(id)
													}
												/>
											),

											timeout: "short",
											position: "bottom-end",
										})
									}
								}}
							>
								{pipelineDetailPageStore.pipeline ? (
									pipelineDetailPageStore.pipeline.id
								) : (
									<TextSkeleton />
								)}
							</Button>
						</View>
					</View.Item>

					<View.Item columns={6}>
						<View gap={1}>
							<Text variant="body-3" color="neutral-faded">
								{pipelineDetailPageStore.accessTokens.tokens
									.length
									? `Access Token (${pipelineDetailPageStore.accessTokens.tokens[0].name})`
									: "Access Token"}
							</Text>
							{pipelineDetailPageStore.accessTokens
								.isFetchingTokens ? (
								<TextSkeleton />
							) : pipelineDetailPageStore.accessTokens
									.hasTokens ? (
								<Button
									variant="outline"
									endIcon={Copy}
									className={css.credential}
									onClick={async () => {
										if (
											pipelineDetailPageStore.accessTokens
												.tokens.length
										) {
											await navigator.clipboard.writeText(
												pipelineDetailPageStore
													.accessTokens.tokens[0]
													.token,
											)

											const id = toast.show({
												color: "neutral",
												icon: Copy,
												title: "Copied Access Token",
												actionsSlot: (
													<Button
														variant="ghost"
														icon={X}
														onClick={() =>
															toast.hide(id)
														}
													/>
												),

												timeout: "short",
												position: "bottom-end",
											})
										}
									}}
								>
									{`${pipelineDetailPageStore.accessTokens.tokens[0].token.slice(
										0,
										17,
									)}...${pipelineDetailPageStore.accessTokens.tokens[0].token.slice(
										-16,
									)}`}
								</Button>
							) : (
								<View paddingTop={1}>
									<Button
										variant="faded"
										color="positive"
										size="small"
										icon={Plus}
										onClick={() => {
											if (
												pipelineDetailPageStore.pipeline
											) {
												modalsStore.createAccessToken.openModal(
													pipelineDetailPageStore
														.pipeline.id,
													async () => {
														await pipelineDetailPageStore.accessTokens.getTokens()
													},
												)
											}
										}}
									>
										Create New Access Token
									</Button>
								</View>
							)}
						</View>
					</View.Item>
					<View paddingTop={52} className={css.container}>
						<Button
							className={css.button}
							color="primary"
							as="a"
							attributes={{ target: "_blank" }}
							href="https://github.com/glassflow/glassflow-python-sdk"
						>
							Set Up Python SDK
						</Button>
					</View>
				</View>
			</Card>
		</View>
	)
})
