import { types } from "mobx-state-tree"
import { OnboardingTipModal } from "./modal"

export const OnboardingTipModalModel = types
	.model("OnboardingTipModal", {
		isOpen: types.optional(types.boolean, false),
		isTransparentOverlay: types.optional(types.boolean, false),
		isBottomAligned: types.optional(types.boolean, false),
		isShownBefore: types.optional(types.boolean, false),
		text: types.optional(types.string, ""),
		closeFunctionId: types.optional(types.string, ""),
	})
	.actions((self) => {
		return {
			openModal(
				text?: string,
				closeFunctionId?: string,
				isShownBefore?: boolean,
				isTransparentOverlay?: boolean,
				isBottomAligned?: boolean,
			) {
				self.text = text || ""
				self.closeFunctionId = closeFunctionId || ""
				self.isTransparentOverlay = isTransparentOverlay || false
				self.isBottomAligned = isBottomAligned || false
				self.isOpen = !isShownBefore
			},
			closeModal() {
				self.isOpen = false
			},
		}
	})
