import type { ReactNode } from "preact/compat"
import { Card, Link, Text, View } from "reshaped"
import css from "./createOption.module.css"

type CreateOptionProps = {
	href: string
	onClick?: (event: MouseEvent) => void
	icon?: ReactNode
	name: string
	target?: string
	isBlue?: boolean
}
export const CreateOption: React.FunctionComponent<CreateOptionProps> = (
	props,
) => {
	return (
		<Link
			variant="plain"
			href={props.href}
			attributes={{ target: props.target }}
			onClick={props.onClick}
			className={props.isBlue ? css.blueCard : css.card}
		>
			<Card as="label" padding={0}>
				<View
					gap={4}
					wrap={false}
					height="100%"
					align="center"
					direction="row"
					padding={8}
				>
					{props.icon}
					<Text variant="body-2" weight="medium">
						{props.name}
					</Text>
				</View>
			</Card>
		</Link>
	)
}
