import { observer } from "mobx-react"
import { Modal, View } from "reshaped"

import { useLocation } from "@swan-io/chicane"
import { useEffect } from "preact/hooks"
import { modalsStore } from "#modals"
import { ExpandableTabTopPanel } from "../../components/PipelineCreation/ExpandableTab/component"
import { DataSink } from "../../pages/PipelineCreateEmailEncryption/tabs/dataSink"
import { getCreatePipelineFunction } from "../../utils"
import css from "./modal.module.css"

export const DemoSinkModal = observer(() => {
	const store = modalsStore.demoSink
	const location = useLocation()

	useEffect(() => {
		if (location.raw.path !== "/pipelines/create") {
			store.closeModal()
		}
	}, [location.raw.path, store.closeModal])

	return (
		<Modal
			className="modal"
			active={store.isOpen}
			padding={6}
			size="1024px"
			onClose={async () => {
				store.closeModal()
				setTimeout(() => {
					document.body.style.overflow = ""
				}, 1000)
			}}
			overlayClassName={css.overlay0}
			blurredOverlay
		>
			<ExpandableTabTopPanel
				title="Data Sink"
				onClose={store.closeModal}
				onNextClick={() =>
					getCreatePipelineFunction(store.nextButtonFunctionId)()
				}
				onPrevClick={() =>
					getCreatePipelineFunction(store.prevButtonFunctionId)()
				}
				prevButtonName={store.prevButtonName}
				nextButtonName={store.nextButtonName}
				withClose={store.withClose}
			/>
			<View paddingTop={4}>
				<DataSink />
			</View>
		</Modal>
	)
})
