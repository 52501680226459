import { createGroup, createRouter, useLinkProps } from "@swan-io/chicane"

const routes = {
	Home: "/",
	...createGroup("Pipelines", "/pipelines", {
		List: "?:page",
		...createGroup("Create", "/create", {
			Blank: "/",
			EmailEncryption: "/email-encryption",
		}),
		...createGroup("Detail", "/:pipelineId", {
			Details: "/",
			DataGenerator: "/data-generator",
			GlassFlowSink: "/glassflow-sink",
			Transformer: "/transformer",
			Tokens: "/tokens",
			Logs: "/logs",
		}),
	}),
	...createGroup("Demo", "/demo", {
		...createGroup("Pipelines", "/pipelines", {
			...createGroup("Detail", "/:pipelineId", {
				DataGenerator: "/data-generator",
				GlassFlowSink: "/",
				Transformer: "/transformer",
				Tokens: "/tokens",
				Logs: "/logs",
			}),
		}),
	}),
	Onboarding: "/home",
	Spaces: "/spaces?:page",
	Profile: "/profile",
	Settings: "/settings",
} as const

export type Routes = typeof routes
export type RouteName = keyof Routes

export const router = createRouter(routes)

export const routeNames = Object.keys(routes) as RouteName[]

export const useAllRoutes = () => router.useRoute(routeNames)

export const getLinkProps = (
	route: string,
): ReturnType<typeof useLinkProps> => {
	let linkProps = useLinkProps({
		href: route,
	})

	return {
		active: linkProps.active,
		onClick(event) {
			linkProps.onClick(event)
		},
	}
}
